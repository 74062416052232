<template>
  <Stat
    title="Totaal geholpen adviesvragers"
    :hint="totaal_adviesvragers"
    :count="345"
    class="md:col-span-6"
    icon="i-heroicons-user-group-20-solid"
    :trend="18"
    :loading="loading"
  />
  <Stat
    title="Terugkerende adviesvragers"
    :hint="terugkerend"
    :count="30"
    :percentage="true"
    class="md:col-span-6"
    icon="i-heroicons-chart-pie-20-solid"
    :trend="0"
    :loading="loading"
  />
  <Stat
    title="Totaal geplande adviesgesprekken"
    :hint="totaal_gepland"
    :count="data?.metadata.total"
    class="md:hidden"
    icon="i-heroicons-chat-bubble-left-right-20-solid"
    :trend="data?.trends?.total"
    :loading="loading"
    :error="error"
    size="sm"
  />
  <Stat
    title="Geannuleerde adviesgesprekken"
    :hint="gepland_canceled"
    :count="canceledPercentage"
    :percentage="true"
    class="md:hidden"
    icon="i-mdi-cancel"
    :trend="data?.trends?.canceled"
    :loading="loading"
    :error="error"
    size="sm"
  />
  <Adviesgesprekken 
    :data="chartData || null"
    :loading="loading"
    :trend="data?.trends?.total"
    :error="!!error"
    @retry="refresh"
    class="md:col-span-12"
  />  
  <AdviesgesprekkenNaarSector
    :data="sectorData || null"
    :loading="loading"
    :error="!!error"
    @retry="refresh"
    class="md:col-span-6 md:row-span-3"
  />  
  <Stat
    title="Totaal geplande adviesgesprekken"
    :hint="totaal_gepland"
    :count="data?.metadata.total"
    class="hidden md:block md:col-span-6 md:row-span-1"
    icon="i-heroicons-chat-bubble-left-right-20-solid"
    :trend="data?.trends?.total"
    :loading="loading"
    :error="error"
    size="sm"
  />
  <Stat
    title="Geannuleerde adviesgesprekken"
    :hint="gepland_canceled"
    :count="canceledPercentage"
    :percentage="true"
    class="hidden md:block md:col-span-6 md:row-span-1"
    icon="i-mdi-cancel"
    :trend="data?.trends?.canceled"
    :loading="loading"
    :error="error"
    size="sm"
  />

  <SubSection label="Servicebeoordeling van het Onderwijsloket">
    <ContentTypes 
      :data="data?.content"
      :loading="loading"
      :error="error"
    />
    
  </SubSection>
  <Notice 
      title="Hoe wij onze service laten beoordelen"
      description="Het Onderwijsloket werkt met een servicebeoordelingscyclus: elk jaar vragen wij gedurende één maand aan al onze adviesvragers of ze onze service willen beoordelen. Het doel hiervan is om doorlopend te blijven monitoren waar onze doelgroep knelpunten ervaart, en hoe wij ons eigen werk beter kunnen uitvoeren. De cijfers die je hier ziet, zijn van onze meest recente cyclus. Via onderstaande button kun je ook de resultaten van de vorige cycli in zien."
      to="/servicebeoordeling"
      label="bekijk alle servicebeoordelingscycli"
      class="md:col-span-12 mb-8"
      hide-clos4
    />
</template>

<script setup lang="ts">
// Examples https://vue-chartjs.org/examples/


const { $api } = useNuxtApp()
const { getDateRangeParam, dateRange, compareBy, getGroupByParam } = useStatsQuery()

const { data, status, error, refresh, clear }: any = useLazyAsyncData(async () => {
  const res = await $api('/stats/advies/scheduling', {
    query: {
      period: 'custom',
      date: getDateRangeParam(),
      includeTrends: compareBy.value,
      groupBy: getGroupByParam()
    }
  })
  return res
}, {
  server: false
})

const { 
  totaal_adviesvragers,
  totaal_gepland,
  terugkerend,
  gepland_canceled
} = useStatsHints().advies


watch([dateRange, compareBy], async () => {
  await refresh()
})

const isLoading = computed(() => status.value !== 'success' && status.value !== 'error')
const loading = refDebounced(isLoading, 200)


const canceledPercentage = computed(() => {
  if (!data.value) return 0
  return Math.floor((data.value?.metadata.canceled / data.value?.metadata.total) * 100)
})


const chartData = computed(() => ({
  labels: !data.value ? [] : Object.keys(data.value?.series),
  datasets: [
    {
      label: 'Adviesgesprekken',
      data: !data.value ? [] : Object.values(data.value?.series).map((val: any) => val.total),
    },
  ],
}))

const { colorList } = useCharts()

const sectorData = computed(() => {
  let labels = !data.value ? [] : Object.keys(data.value?.metadata.sectoren)
  const backgroundColor = labels.map((_, index) => colorList.value[index % colorList.value.length] as string);

  return {
    labels,
    datasets: [
      {
        label: 'Adviesgesprekken',
        backgroundColor,
        data: !data.value ? [] : Object.values(data.value?.metadata.sectoren) as number[] ,
      },
    ],
  }
})
</script>