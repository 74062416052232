import type { Show } from "~~/types"


export const useShows = () => {
    const shows: Show[] = [
        { 
            label: 'Grote Pauze', 
            id: '179daZbGUFmMCOeVJ2Tv77', 
            key: 'grote_pauze', 
            url: 'https://open.spotify.com/show/179daZbGUFmMCOeVJ2Tv77', 
            public_id: 'onderwijsloket/podcast/grote_pauze/branding/grote_pauze_lg_1-1_cmsqyl', 
            descriptionShort: "Ervaringsdeskundigen vertellen wat werken in het onderwijs zo mooi maakt.",
            description: "Welkom bij Grote Pauze, dé podcast van het Onderwijsloket. In deze podcast gaan Anna en Sarah, beide adviseurs bij het Onderwijsloket, in gesprek met ervaringsdeskundigen en andere onderwijshelden, over wat werken in het onderwijs zo mooi maakt!  Het Onderwijsloket is een initiatief van de Vereniging Hogescholen (VH) en de Universiteiten van Nederland (UNL). Ons team van adviseurs beantwoordt gratis al je vragen over een carrière in het onderwijs. Denk jij eraan om over te stappen? Bezoek dan onze website www.onderwijsloket.com" 
        },
        { 
            label: 'Klaar voor de Stap', 
            id: '3YS6E1qILrKYUweyQNiApf', 
            key: 'klaar_voor_de_stap', 
            url: 'https://open.spotify.com/show/3YS6E1qILrKYUweyQNiApf', 
            public_id: 'onderwijsloket/voorbereiden/other_assets/klaar_vd_stap_lg_1-1_zh72gb', 
            descriptionShort: "Gouden tips van de overstappers die je voorgingen.",
            description: "In Klaar voor de Stap spreken we mensen die midden in hun overstap naar het onderwijs zitten of deze net hebben afgerond. Welke lessen hebben zij geleerd uit hun overstap? Met welke onverwachte situaties werden ze geconfronteerd? En vooral: hoe kunnen toekomstige overstappers hier zich zo goed mogelijk op voorbereiden?" 
        }
    ]

    // const app: Ref<App["label"]> = useState('app', () => 'onderwijsloket.com')

    const route = useRoute()
    const router = useRouter()

    const selectedShow = computed({
        get() {
            const index = shows.findIndex((item) => item.key === route.query.show)
            if (index === -1) {
                return 0
            }

            return index
        },
        set(value: number) {
            if (shows[value]) {
                // Hash is specified here to prevent the page from scrolling to the top
                router.replace({
                    query: {
                        ...route.query,
                        show: shows[value].key
                    }
                })
            } else if (shows[0]) {
                router.replace({ query: { ...route.query, show: shows[0].key } })
            }
        }
    })

    const show: Ref<Show> = computed(() => shows[selectedShow.value] || shows[0] as Show)



    const extractEpisodeCodes = (title: string): { code: string | null, title: string } => {
        // Check if it's a trailer
        if (title.toLowerCase().includes('trailer')) {
        return { code: 'trailer', title };
        }

        // Match format {number}.{number} at the start
        const match = title.match(/^(\d+)\.(\d+)/);

        if (match && match[1] && match[2]) {
        const season = match[1].padStart(2, '0');
        const episode = match[2].padStart(2, '0');
        const cleanTitle = title.replace(match[0], '').trim();
        return { code: `S${season}E${episode}`, title: cleanTitle };
        }

        return { code: null, title };
    };

    return {
        shows,
        selectedShow,
        show,
        extractEpisodeCodes
    }
}
