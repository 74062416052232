<template>
  <IconCard
    :ui="{
      background: 'bg-white dark:bg-gray-700/50',
    }"
    :icon="icon"
    color="gray"
  >
    <template #header>
      <div class="flex lg:items-center gap-4">
        <div class="flex flex-col sm:flex-row gap-y-2 pt-1 sm:pt-0 gap-x-4 sm:items-center grow ">
          <h4 class="text-sm sm:text-lg mb-0 text-gray-600 dark:text-gray-400">{{title}}</h4>
          <Trend 
            v-if="typeof trend === 'number'"
            :count="trend"
            :loading="loading"
            class="relative sm:top-px"
          />
        </div>
        <Hint v-if="hint" :text="hint"/>
      </div>
    </template>
    <template #default>
      <div 
        v-if="loading"
        class="flex flex-col py-6 px-4 gap-3 items-center justify-center animate-pulse ring-1 ring-gray-300 dark:ring-gray-600 rounded-lg min-h-full" 
        :style="!!height ? {height: height + 'px'} : {}"
      >
        <UIcon name="i-heroicons-arrow-path" class="animate-spin text-xl" />
        <p class="text-xs">We halen gegevens op...</p>
      </div>
      <div 
        v-else-if="error"
        class="flex flex-col py-6 px-4 gap-3 items-center justify-center bg-error-50 ring-1 ring-error-300 dark:ring-error-600 dark:bg-error-900/20 rounded-lg text-error-500 min-h-full" 
        :style="!!height ? {height: height + 'px'} : {}"
      >
        <div class="text-center w-full p-4 max-w-md">
          <UIcon name="i-heroicons-exclamation-triangle-20-solid" class="text-xl" />
          <p class="text-xs mb-4">{{ errorMessage }}</p>
          <UButton 
            color="error" 
            size="xs" 
            variant="solid" 
            :icon="permanentFailure ? 'i-heroicons-exclamation-triangle-16-solid' : 'i-heroicons-arrow-path'" 
            :disabled="permanentFailure" 
            :loading="retryLoading" 
            @click="retry"
          >
            {{ permanentFailure ? 'mislukt...' : 'probeer opnieuw' }}
          </UButton>
        </div>
      </div>
      <div 
        v-else-if="empty"
        class="flex flex-col py-6 px-4 gap-3 items-center text-center justify-center bg-gray-50 dark:bg-gray-900 ring-1 ring-gray-300 dark:ring-gray-600 rounded-lg min-h-full" 
        :style="!!height ? {height: height + 'px'} : {}"
      >
        <UIcon name="i-heroicons-inbox-stack-20-solid" class="text-xl" />
        <p class="text-xs">{{ emptyHint }}</p>
      </div>
      
      <div v-else :style="!!height ? {height: height + 'px'} : {}">
        <slot />
      </div>
    </template>
  </IconCard>
</template>

<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    icon?: string
    title: string
    loading?: boolean
    height?: number
    trend?: number | 'infinite'
    hint?: string
    empty?: boolean
    emptyHint?: string
    error?: boolean,
    errorMessage?: string
  }>(), {
    // height: 160,
    emptyHint: 'We konden geen gegevens vinden voor de geselecteerde periode',
    errorMessage: 'Er ging iets mis tijdens het inladen van de data. Ververs de pagina, of klik op de button hieronder om het opnieuw te proberen.',
  }
)

const permanentFailure = ref(false)
const retryLoading = ref(false)
const retry = () => {
  retryLoading.value = true
  emit('retry')
  setTimeout(() => {
    if (retryLoading.value) {
      retryLoading.value = false
      permanentFailure.value = true
    }
  }, 5000)
}

watch(() => props.error, (newVal) => {
  if (!newVal) {
    permanentFailure.value = false
    retryLoading.value = false
  }
})

const emit = defineEmits<{
  (e: 'retry'): void
}>()
</script>