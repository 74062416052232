<template>
  <NuxtLayout 
    name="dashboard"
    avatar-src="https://res.cloudinary.com/onderwijsin/image/upload/v1726470965/brand/onderwijsloket-square-OG_xaes77.jpg"
    :title="dashboardTitle"
    :subtitle="dashboardSubtitle"
    :badge="periodLabel"
    badge-hint="Je kunt deze periode altijd zelf aanpassen via de datumseletcie tool, rechts is je scherm."
    badge-icon="i-heroicons-calendar-days-20-solid"
    :tabs="tabs"
  >
    <template #filters>
      <USelectMenu 
        v-model="compareBy" 
        :options="[...compareOptions]" 
        value-attribute="key" 
        label-attribute="label" 
        size="lg"
        class="lg:min-w-[220px] period-select grid sm:block "
      >
      <UButton 
        color="white" 
        size="xl" 
        class="lg:min-w-[220px] h-full sm:h-[39.2px]"
        icon="i-heroicons-arrows-right-left-16-solid"
        :ui="{
          color: { 
            white: { 
              solid: 'text-primary-400 hover:text-primary-500'
            }
          },
         
        }"
      >
        <span class="text-2xs hidden lg:inline">{{ selectedComparison?.label }}</span>
      </UButton>
      <template #option="{ option }">
        <span class="truncate text-3xs">{{ option.label }}</span>
      </template>
      </USelectMenu>
      <DateRangeSelector 
        v-model="dateRange"
        class="relative z-50 grid" 
      />
    </template>

    <template #default="{ selectedTab }">
        <KeepAlive>
          <Advies v-if="selectedTab === 0" />
        </KeepAlive>
        <KeepAlive>
          <Platform v-if="selectedTab === 1" />
        </KeepAlive>
        <KeepAlive>
          <Content v-if="selectedTab === 2" />
        </KeepAlive>
        <KeepAlive>
          <Media v-if="selectedTab === 3" />
        </KeepAlive>
      <SignupCard class="md:col-span-12" />
    </template>
</NuxtLayout>
</template>


<script setup lang="ts">

//TODO sync date selector with useStatsQuery State (this is disconnect due to base layer iumplemenattion)

definePageMeta({
  name: "home",
});

const tabs = [{
  index: 0,
  label: 'Advies',
  icon: 'i-heroicons-chat-bubble-left-right',
}, {
  index: 1,
  label: 'Platform',
  icon: 'i-heroicons-users',
}, {
  index: 2,
  label: 'Content',
  icon: 'i-heroicons-document-text',
},
  {
  index: 3,
  label: 'Media',
  icon: 'i-heroicons-film',
}]

const { dateRange, getDateRangeParam, compareBy, report, setReport, clearReport } = useStatsQuery()

const router = useRouter()
watch(compareBy, (newVal) => {
    router.replace({ 
        query: { 
            ...route.query,
            compare: newVal
        } 
    })
})

const dashboardTitle = computed(() => {
   if (report.value.month) {
      return `Rapportage Onderwijsloket ${report.value.month} ${report.value.year}`
   }
   return 'Statistieken over het Onderwijsloket'
})

const dashboardSubtitle = computed(() => {
   if (report.value.month) {
      return `Statistieken over de dienstverlening en de doelgroep van het Onderwijsloket in  ${report.value.month} ${report.value.year}`
   }
   return 'Kwantitatieve informatie over onze dienstverlening en het aanbod van het Onderwijsloket.'
})


import { nl } from 'date-fns/locale';
const periodLabel = computed(() => {
  if (report.value.month) return undefined
  return `${format(dateRange.value.start, 'd MMM yyyy', { locale: nl })} tot ${format(dateRange.value.end, 'd MMM yyyy', { locale: nl })}`
})

useSeoMeta({
  title: dashboardTitle,
  description: dashboardSubtitle,
})

const route = useRoute()

const compareOptions = [{
  index: 0,
  label: 'Vergelijk met vorige periode',
  key: 'previous_period',
  icon: 'i-heroicons-arrow-uturn-left-20-solid',
}, {
  index: 1,
  label: 'Vergelijk met vorig jaar',
  key: 'year_over_year',
  icon: 'i-heroicons-calendar-days-20-solid',
}, {
  index: 2,
  label: 'Geen vergelijking',
  key: 'none',
  icon: 'i-mdi-cancel',
}] as const

type Comparison = typeof compareOptions[number]

const selectedComparison = computed(() => compareOptions.find(opt => opt.key === compareBy.value) as Comparison || null)

if (route.query.compare) {
  const comparison = route.query.compare.toString()
  if (compareOptions.map(a => a.key).includes(comparison as Comparison["key"])) compareBy.value = comparison as Comparison["key"]
}


import { format } from 'date-fns';
import type { App, Show } from "~~/types"
const { apps, selectedApp } = useApps()

if (route.query.app) {
    const app = route.query.app.toString()
    if (apps.map(a => a.label).includes(app as App["label"])) selectedApp.value = apps.findIndex(a => a.label === app)
}

const { shows, selectedShow } = useShows()

if (route.query.show) {
    const show = route.query.show.toString()
    if (shows.map(a => a.label).includes(show as Show["label"])) selectedShow.value = shows.findIndex(a => a.label === show)
}




if (route.query.rapport || route.query.report) {
  setReport()
} 
else if (route.query.date) {
    const date = route.query.date

    let [start, end]: [string | Date, string | Date] = date.toString().split(',') as [string, string]
    if (start && end) {
        [start, end] = [new Date(start), new Date(end)]
        if (!isNaN(start.getTime()) && !isNaN(end.getTime())) {
          dateRange.value.start = start
          dateRange.value.end = end
        }
    }
}


watch(dateRange, () => {
    router.replace({ 
        query: { 
            ...route.query,
            date: getDateRangeParam()
        } 
    })
})

onMounted(() => {
  watch(dateRange, () => {
    clearReport()
  })
})


</script>


<style lang="postcss">
.period-select .select-menu {
  min-width: 220px
}
</style>