<template>
  <DashboardCard
    :icon="icon"
    :title="title"
    :loading="false"
    :hint="hint"
    :empty="!loading && empty"
    :empty-hint="emptyHint"
    :error="!!error"
    @retry="emit('retry')"
    class="min-h-[280px]"
    :class="loading ? 'sm:min-h-[440px]' : ''"
  >
     <ul v-if="loading && !hasInitialData" class="space-y-2">
        <li
          v-for="index in 10"
          class="flex flex-row items-center justify-between gap-5"
        >
          <div class="flex flex-row gap-3 items-center grow">
            <USkeleton class="h-5 w-5" />
            <USkeleton class="h-3 w-[90%]" />
          </div>
          <USkeleton class="h-4 w-12" />
        </li>
     </ul>
     <ul v-else class="space-y-2">
      <slot name="default" />
     </ul>

     <CardDetails 
      v-model="showMore"
      :title="title"
      :icon="icon"
      :show-more-label="showMoreLabel"
      >
        <ul class="space-y-2" :class="loading ? 'mb-2' : ''">
          <slot name="details" />
        </ul>
        <ul v-if="loading" class="space-y-2">
          <li
            v-for="index in 20"
            class="flex flex-row items-center justify-between gap-5"
          >
            <div class="flex flex-row gap-3 items-center grow">
              <USkeleton class="h-5 w-5" />
              <USkeleton class="h-3 w-[90%]" />
            </div>
            <USkeleton class="h-4 w-12" />
          </li>
      </ul>
     </CardDetails>


  </DashboardCard>
</template>

<script lang="ts" setup>
const props = defineProps<{
  icon?: string
  title: string
  loading?: boolean
  hasInitialData: boolean,
  hint?: string
  empty?: boolean
  emptyHint?: string
  error?: boolean,
  errorMessage?: string
  showMoreLabel?: string
}>()


const emit = defineEmits<{
  (e: 'retry'): void
  (e: 'showMore'): void
}>()

const showMore = ref(false)

watch(showMore, (newVal) => {
  if (newVal) emit('showMore')
})
  
</script>

<style>

</style>