<template>
  <div class="sm:flex sm:flex-row items-center justify-end gap-3 ">
    <span class="text-xs text-gray-500 hidden sm:inline">toon info over</span>
    <USelectMenu 
      v-model="selected" 
      :options="[...options]" 
      value-attribute="key" 
      label-attribute="label" 
      color="gray"
      variant="outline"
      size="sm"
      class="min-w-[160px]"
      :ui="{
        size: {
          sm: 'text-xs'
        }
      }"
    >
      <template #option="{option}">
        <span class="truncate text-2xs">{{ option.label }}</span>
      </template>
    </USelectMenu>
  </div>
</template>

<script lang="ts" setup>

const options = [
  {
    key: 'pageviews',
    label: 'Paginaweergaven'
  },
  {
    key: 'visitors',
    label: 'Bezoekers'
  },
  {
    key: 'visits',
    label: 'Sessies'
  },
] as const

import type { PlausibeDataType } from '~~/types';
const selected: Ref<'pageviews' | 'visitors' | 'visits'> = ref(options[0].key)

const emit = defineEmits<{
  (e: 'select', payload: PlausibeDataType): void
}>()

watch(selected, (newVal) => {
  emit('select', newVal)
}, {
  immediate: true
})

</script>

<style>

</style>