<template>
  <DashboardCard
    icon="i-heroicons-calendar-days-20-solid"
    title="Geplande adviesgesprekken"
    :loading="loading"
    :height="400"
    :trend="trend"
    :hint="totaal_gepland"
    :empty="isEmpty"
    :empty-hint="emptyHint"
    :error="!!error"
    @retry="emit('retry')"
  >
    <Line :data="data" :options="chartOptions"/>
  </DashboardCard>
  
</template>

<script setup lang="ts">
type DataSet = {
  label: string
  data: number[]
}
const props = defineProps<{
  loading: boolean
  data: {
    labels: string[],
    datasets: DataSet[]
  }
  trend?: number | 'infinite'
  error?: any
}>()

const emit = defineEmits<{
  (e: 'retry'): void
}>()

const { 
  totaal_gepland,
} = useStatsHints().advies

const { 
  savvycal,
} = useStatsHints().empty

const {
    Line,
    baseLineOptions
} = useCharts()

const { dateRange } = useStatsQuery()

const isEmpty = computed(() => !props.data.datasets[0]?.data.length || props.data.datasets[0]?.data.reduce((acc, cur) => acc + cur,0) === 0)
const emptyHint = computed(() => {
  if (dateRange.value.end < new Date(savvycal.date)) return savvycal.text
})

const chartOptions: Ref<any> = ref(baseLineOptions)
</script>