<template>
    <Stat
        title="Realtime bezoekers"
        :hint="realtime"
        icon="i-heroicons-bolt-20-solid"
        :loading="!data?.realtimeVisitors && loading"
    >
        <template #count>
            <span class="relative">
                <span class="text-xl font-bold">{{data?.realtimeVisitors }}</span>
                <div class="absolute -top-1 -right-0.5">
                    <span class="animate-ping-slow absolute inset-0 inline-flex h-2.5 w-2.5 rounded-full bg-success-400 opacity-75"></span>
                    <span class=" inline-flex rounded-full absolute inset-0 h-2.5 w-2.5 bg-success-500"></span>
                </div>
            </span>
            
        </template>
    </Stat>
</template>

<script setup lang="ts">
const props = defineProps<{
    app: string
}>()

const { $api } = useNuxtApp()
const { data, status, error, refresh, clear }: any = useLazyAsyncData(async () => {
  const res = await $api('/stats/platform/realtime', {
    query: {
      app: props.app
    }
  })
  return res
}, {
  server: false
})

const isLoading = computed(() => status.value !== 'success' && status.value !== 'error')
const loading = refDebounced(isLoading, 200)

watch(() => props.app, async () => {
    await refresh()
    resetRefreshCount()
})


const {
    realtime,
    max_realtime_refresh_count
} = useStatsHints().platform

const currentCount = ref(0)

const resetRefreshCount = () => {
    currentCount.value = 0
}

const { pause, resume, isActive } = useIntervalFn(async () => {
    if (currentCount.value < max_realtime_refresh_count) {
        await refresh()
        currentCount.value++
    } else {
        pause()
    }
}, 20000)

onActivated(() => {
    resetRefreshCount()
    resume()
})

onDeactivated(() => {
    resetRefreshCount()
    pause()
})

</script>