<template>
  <DashboardCard
    icon="i-heroicons-briefcase-20-solid"
    title="Adviesgesprekken naar sector"
    :loading="loading"
    :height="240"
    :hint="geplande_gesprekken_naar_sector"
    :empty="isEmpty"
    :empty-hint="emptyHint"
    :error="!!error"
    @retry="emit('retry')"
  >
        <Pie
          :data="data"
          :options="chartOptions"
        />
  </DashboardCard>
  
</template>

<script setup lang="ts">

// TODO handle no data (prior to june 2024)
type DataSet = {
  label: string
  backgroundColor: string[]
  data: number[]
}
const props = defineProps<{
  loading: boolean
  data: {
    labels: string[],
    datasets: DataSet[]
  }
  trend?: number | 'infinite'
  error?: any
}>()

const emit = defineEmits<{
  (e: 'retry'): void
}>()

const { 
  geplande_gesprekken_naar_sector,
} = useStatsHints().advies

const {
  Pie,
  basePieOptions
} = useCharts()


const { 
  savvycal,
} = useStatsHints().empty
const { dateRange } = useStatsQuery()

const isEmpty = computed(() => !props.data.datasets[0]?.data.length || props.data.datasets[0]?.data.reduce((acc, cur) => acc + cur,0) === 0)
const emptyHint = computed(() => {
  if (dateRange.value.end < new Date(savvycal.date)) return savvycal.text
})

const chartOptions = ref(basePieOptions)
</script>