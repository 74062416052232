<template>
  <li class="flex flex-row items-start gap-3 justify-between">
    <span class="leading-5">{{getFlagEmoji(props.data.region.split('-')[0] as string)}}</span>
    <span class="grow text-sm truncate">{{ region ? region : props.data.region }} <UBadge color="gray" size="2xs" variant="subtle" class="ml-3">{{ country }}</UBadge></span>
    
    <span class="text-sm font-bold shrink-0 ml-3">{{ count }}</span>
  </li>
</template>

<script lang="ts" setup>
const props = defineProps<{
  data: {
    region: string
    visitors: number
  }
}>()



const displayNames = new Intl.DisplayNames(['nl'], { type: 'region' });
const country = computed(() => props.data.region.includes('-') ? displayNames.of(props.data.region.split('-')[0] as string) : '')

const provinces = {
  'NL-GR': 'Groningen',
  'NL-FR': 'Friesland',
  'NL-DR': 'Drenthe',
  'NL-OV': 'Overijssel',
  'NL-FL': 'Flevoland',
  'NL-GE': 'Gelderland',
  'NL-UT': 'Utrecht',
  'NL-NH': 'Noord-Holland',
  'NL-ZH': 'Zuid-Holland',
  'NL-ZE': 'Zeeland',
  'NL-NB': 'Noord-Brabant',
  'NL-LI': 'Limburg'
};

type Key = keyof typeof provinces

const region = computed(() => {
  if (!!provinces[props.data.region as Key]) return provinces[props.data.region as Key]
  return null
})

function getFlagEmoji(countryCode: string): string {
  // Normalize country code to uppercase and validate length
  const codePoints = countryCode
    .toUpperCase()
    .split('')
    .map(char => 0x1F1E6 + (char.charCodeAt(0) - 65));

  return String.fromCodePoint(...codePoints);
}

const count = computed(() => formatNumber(props.data.visitors))
</script>

<style>

</style>