import { 
    Chart, 
    Title, 
    Tooltip, 
    Legend, 
    BarElement, 
    PointElement,
    LineElement, 
    CategoryScale, 
    LinearScale ,
    ArcElement,
    Filler,
    type ChartData,
    type LegendItem
} from 'chart.js'

import { Line, Pie } from 'vue-chartjs'
import type { ChartProps } from 'vue-chartjs'
import { _main, _secondary, _aux1, _aux2, _aux3, _tertiary, _grays } from '#tailwind-config/theme/colors';
import {  } from '#tailwind-config/theme/accentColor';

export const useCharts = () => {
    
    Chart.register(
        CategoryScale, 
        LinearScale, BarElement, 
        Title, 
        Tooltip, 
        Legend,
        PointElement,
        LineElement, 
        ArcElement,
        Filler
    )

    

    const { isDark, isDyslexic } = useTheme()

    const colorList = computed(() => ([
        !isDark.value ? _main[300]                : _main[900],
        !isDark.value ? _aux1[300]                : _aux1[900],
        !isDark.value ? _tertiary[300]            : _tertiary[900],
        !isDark.value ? _secondary[300]           : _secondary[900],
        !isDark.value ? _aux2[300]                : _aux2[900],
        !isDark.value ? _aux3[300]                : _aux3[900]
    ]))


    // TODO this should be a css variable
    const fontFam = computed(() => isDyslexic.value ? "'OpenDyslexic', 'sans-serif'" : "'F37Ginger', 'sans-serif'" )

    const defaultFont = computed(() => ({
        size: 12,
        family: fontFam.value,
        lineHeight: 1.5
    }))

    const tooltipOptions = computed(() => ({
        enabled: true,
        backgroundColor: isDark.value ? _grays[900] : '#fff',
        borderColor: isDark.value ? _grays[600] : _grays[200],
        borderWidth: 1,
        titleColor: isDark.value ? _grays[200] : _grays[800],
        bodyColor: isDark.value ? _grays[200] : _grays[800],
        titleFont: defaultFont.value,
        bodyFont: defaultFont.value,
        bodySpacing: 4,
        padding: 10,
        usePointStyle: true,
        boxPadding: 3,
        boxHeight: 8,
        boxWidth: 8,
        intersect: false
    }))

    

    // const baseLineOptions: Ref<ChartProps["options"]> = computed(() => ({
    const baseLineOptions = computed(() => ({
        responsive: true,
        maintainAspectRatio: false,
        
        elements: {
            point: {
                radius: 5,
                backgroundColor: isDark.value ? _main[200] : _main[400],
            },
            line: {
                tension: 0.35,
                borderColor: isDark.value ? _grays[400] : _grays[300],
                fill: {
                    target: 'origin',
                },
                backgroundColor: isDark.value ? _grays[800] : _main[25],
            }
        },
        scales: {
            x: {
                grid: {
                    display: false
                },
                ticks: {
                    color: isDark.value ? _grays[400] : _grays[500],
                    font: defaultFont.value,
                },
                border: {
                    color: isDark.value ? _grays[900] : _grays[200],
                },
            },
            y: {
                grid: {
                    color: isDark.value ? _grays[900] : _grays[200],
                },
                ticks: {
                    color: isDark.value ? _grays[400] : _grays[500],
                    font: defaultFont.value,
                },
                border: {
                    color: isDark.value ? _grays[900] : _grays[200],
                },
                suggestedMin: 0
            }
        },
        plugins: {
            legend: {
                display: false,
            },
            tooltip: tooltipOptions.value,
            filler: {
                drawTime: 'beforeDraw',
                propagate: true
            }
        }
    }))


    const basePieOptions = computed(() => ({
        responsive: true,
        maintainAspectRatio: false,
        elements: {
            arc: {
                borderColor: isDark.value ? _grays[500] : _grays[100]
            }
        },
        plugins: {
            legend: {
                align: 'start' as 'start',
                position: 'left' as 'left',
                labels: {
                    boxWidth: 10,
                    boxHeight: 10,
                    font: defaultFont.value,
                    usePointStyle: true,
                    color: isDark.value ? _grays[300] : _grays[700],
                    sort: (a:LegendItem, b: LegendItem, data: ChartData) => {
                        const aIndex = data.labels?.indexOf(a.text)
                        const bIndex = data.labels?.indexOf(b.text)
                        const dataSet = data.datasets[0]?.data
                        if (!dataSet || typeof aIndex !== 'number' || typeof bIndex !== 'number' || typeof dataSet[bIndex] !== 'number' || typeof dataSet[aIndex] !== 'number') return 0
                        return dataSet[bIndex] - dataSet[aIndex]
                    }
                },
                
            },
            tooltip: {
                ...tooltipOptions.value,
                intersect: true
            }
        }
    }))

    
    return {
        Line,
        Pie,
        baseLineOptions,
        basePieOptions,
        colorList
    }
}