<template>
  <li class="flex flex-row items-start gap-3 justify-between w-full">
    <div class="flex flex-row items-start gap-3 select-none text-gray-700 dark:text-gray-200">
      <UIcon name="i-heroicons-magnifying-glass-16-solid" class="w-5 h-5 text-gray-500" />
      <span class="text-sm truncate">{{ !!data.search.trim() ? data.search : '(empty)' }}</span>
    </div>
    <span class="text-sm font-bold">{{ count }}</span>
  </li>
</template>

<script lang="ts" setup>
const props = defineProps<{
  data: {
    search: string
    count: number
    nbHits: number
  }
}>()


const count = computed(() => formatNumber(props.data.count))
const hits = computed(() => formatNumber(props.data.nbHits))
</script>

<style>

</style>