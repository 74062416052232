<template>
  <li class="flex flex-row items-center gap-3 justify-between">
    <div class="h-6 w-6 grid place-items-center rounded-lg bg-gray-100 dark:bg-gray-700 shrink-0">
      <span class="font-bold text-xs">{{ data.ranking }}</span>
    </div>

    <NuxtLink class="grow text-sm truncate">{{ name }}</NuxtLink>

    <div class="inline-flex gap-2 items-start ml-3 shrink-0">
      <UBadge v-if="code" color="gray" size="2xs" variant="subtle">
        {{code}}
      </UBadge>
      <UBadge color="secondary" size="2xs" variant="subtle">
        <UIcon name="i-heroicons-play-16-solid" class="h-3 w-3 mr-1" />
        <span>{{ data.plays }}</span>
      </UBadge>
    </div>
  </li>
</template>

<script lang="ts" setup>
const props = defineProps<{
  data: {
    id: number;
    externalId: string;
    name: string;
    season: number | null;
    isTrailer: boolean;
    publishDate: string;
    plays: number;
    podcastShow: string;
    updatedAt: string;
    ranking: number;
}
}>()

const { extractEpisodeCodes } = useShows()

const name = computed(() => {
  return extractEpisodeCodes(props.data.name).title
})

const code = computed(() => {
  return extractEpisodeCodes(props.data.name).code
})


</script>

<style>

</style>