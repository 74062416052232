<template>
  <ListCard
    :icon="icon"
    :title="title"
    :loading="isLoading"
    :hint="top_searches"
    :has-initial-data="!!firstSet.length"
    :empty="isEmpty"
    :empty-hint="emptyHint"
    :error="!!error"
    @retry="refresh"
    @show-more="showMore = true"
  >
      <SearchQuery v-for="source in firstSet" :data="source" />

     <template #details>
      <SearchQuery v-for="source in data" :data="source" no-truncate />
     </template>


    </ListCard>
  
</template>

<script setup lang="ts">

const props = defineProps<{
  index: string
}>()
const { $api } = useNuxtApp()
const { dateRange, getDateRangeParam } = useStatsQuery()
const { data, status, error, refresh, clear }: any = useLazyAsyncData(async () => {
  const res: any = await $api(`/stats/platform/search/${props.index}/top-searches`, {
    query: {
      period: 'custom',
      date: getDateRangeParam(),
      prop: 'regions',
      limit: showMore.value ? 100 : 10
    }
  })
  return res.count || res
}, {
  server: false
})

const showMore = ref(false)

const isLoading = computed(() => status.value !== 'success' && status.value !== 'error')

watch([dateRange, showMore, () => props.index], async () => {
  await refresh()
})

const icon = 'i-heroicons-magnifying-glass-plus-20-solid'
const title = "Top searches"

const { 
  top_searches,
} = useStatsHints().platform


const { 
  algolia,
} = useStatsHints().empty

const isEmpty = computed(() => !data.value?.length)
const emptyHint = computed(() => {
  if (dateRange.value.end < new Date(algolia.date)) return algolia.text
})

const firstSet = computed(() => {
  if (!data.value) return []
  return data.value.slice(0, 10)
})



</script>