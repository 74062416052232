<template>
  <IconCard
    :ui="{
      background: 'bg-white dark:bg-gray-700/50',
      base: data?.demo ? 'opacity-50 hover:opacity-70 transition-opacity flex flex-col' : 'flex flex-col',
      ring: !!error ? 'ring-1 ring-error-500 dark:ring-error-500' : 'ring-1 ring-secondary-200 dark:ring-gray-600',
    }"
    color="secondary"
    :icon="icon"
    size="lg"
  >
    <template #header>
      <div 
        class="w-full flex items-center gap-4 flex-wrap"
        :class="loading ? 'mb-3' : 'mb-1'"
      >
        <USkeleton v-if="loading" class="h-4 w-1/2"/>
        <h6 v-else class="text-xs md:text-sm mb-0 text-gray-600 dark:text-gray-400" :class="[size === 'sm' ? 'md:leading-snug' : '', error ? 'text-error-500' : 'text-gray-600 dark:text-gray-400']">{{ data?.name }}</h6>
        <UBadge v-if="!loading && data?.demo" color="gray" size="xs" :ui="{size: { xs: 'leading-4'}}">Demo</UBadge>
      </div>
      <div class="flex gap-2 items-start flex-wrap">
        <USkeleton v-if="loading" class="h-4 w-24"/>
        <UBadge 
          v-else
          :color="data?.userCount === 0 ? 'gray' : 'success'"
          variant="subtle"
          size="2xs"
          :class="data?.userCount === 0 ? 'opacity-50' : ''"
        >
          <UIcon name="i-heroicons-users-16-solid" class="mr-1" />
          <span>{{ count }} gebruikers</span>
        </UBadge>
      </div>
    </template>
    <div class="flex h-full w-full flex-col justify-between gap-6">
      <div v-if="loading" class="flex flex-col gap-2 w-full">
            <USkeleton class="h-3 w-full"/>
            <USkeleton class="h-3 w-3/5"/>
            <div class="flex gap-2 flex-wrap gap-y-1 mt-2">
              <USkeleton class="h-5 w-1/5"/>
              <USkeleton class="h-5 w-1/5"/>
            </div>
            <USkeleton class="h-8 w-32 mt-6"/>
        </div>
        <div v-else  class="flex flex-col gap-4 w-full items-start h-full justify-between">
          <div>
            <p class="text-sm text-gray-600 dark:text-gray-300 mb-3">{{data?.description}}</p>
            <div class="flex gap-2 flex-wrap gap-y-1">
              <UBadge 
                v-if="productionDate && !endOfLifeDate"
                color="gray"
                variant="subtle"
                size="2xs"
              >
                <UIcon name="i-heroicons-calendar-16-solid" class="h-2.5 w-2.5 mr-1" />
                <span>in gebruik genomen op <strong>{{ productionDate }}</strong></span>
              </UBadge>
              <UBadge 
                v-if="analyticsDate"
                color="gray"
                variant="subtle"
                size="2xs"
              >
                <UIcon name="i-heroicons-user-group-16-solid" class="h-2.5 w-2.5 mr-1" />
                <span>gebruikstatistieken vanaf <strong>{{ analyticsDate }}</strong></span>
              </UBadge>
              <UBadge 
                v-if="endOfLifeDate"
                color="error"
                variant="subtle"
                size="2xs"
              >
                <UIcon name="i-mdi-cancel" class="h-2.5 w-2.5 mr-1" />
                <span>Uit gebruik gegeaan op <strong>{{ endOfLifeDate }}</strong></span>
              </UBadge>
            </div>
          </div>
          
          <UButton
            :to="data?.url"
            target="_blank"
            color="secondary"
            variant="ghost"
            size="sm"
            icon="i-heroicons-arrow-up-right-16-solid"
            trailing
            class="-mx-2"
          >
            ga naar tool
          </UButton>
        </div>
    </div>

  </IconCard>
  
</template>

<script setup lang="ts">

const props = withDefaults(defineProps<{
  data?: {
    name: string,
    description: string
    demo?: boolean
    url?: string
    userCount?: number
    productionDate: null | string
    endOfLifeDate: null | string
    analyticsFromDate: null | string
  }
  
  loading: boolean
  size?: 'sm'
  error?: any
}>(), {
})

const icon = computed(() => {
  if (props.data?.name === 'Kennisbank') return 'i-heroicons-information-circle-20-solid'
  if (props.data?.name === 'Routetool') return 'i-heroicons-arrow-trending-up-20-solid'
  if (props.data?.name === 'Navigator') return 'i-heroicons-academic-cap-20-solid'
  if (props.data?.name === 'Boekingstool') return 'i-heroicons-calendar-date-range-20-solid'
  if (props.data?.name === 'Klaar voor de Stap') return 'i-mdi-tools'
  if (props.data?.name === 'In de buurt') return 'i-mdi-crosshairs-gps'
  return 'i-heroicons-document-text-20-solid'
})


const count = computed(() => formatNumber(props.data?.userCount || 0))


import { format } from 'date-fns';

const productionDate = computed(() => !!props.data?.productionDate ? format(new Date(props.data.productionDate), 'dd-MM-yy') : null)
const endOfLifeDate = computed(() => !!props.data?.endOfLifeDate ? format(new Date(props.data.endOfLifeDate), 'dd-MM-yy') : null)
const analyticsDate = computed(() => !!props.data?.analyticsFromDate ? format(new Date(props.data.analyticsFromDate), 'dd-MM-yy') : null)

</script>