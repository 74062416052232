<template>
  <div class="flex flex-col gap-y-4 sm:gap-6 lg:gap-8 md:col-span-6">
    <UCard
      :ui="{
        background: 'bg-white dark:bg-gray-700/50',
        ring: 'ring-1 ring-secondary-200 dark:ring-gray-600',
        header: {
          padding: 'md:pb-0'
        },
        body: {
          padding: 'md:pt-4'
        }
      }"
      class="grow z-10"
    >
      <template #header>
        <div class="flex lg:items-center gap-4">
          <UIcon name="i-mdi-database-search" class="shrink-0 text-xl text-secondary-500 dark:text-secondary-200 relative top-[0.18rem] lg:top-0" />
          <div class="flex flex-col sm:flex-row gap-y-2 pt-1 sm:pt-0 gap-x-4 sm:items-center grow ">
            <h4 class="text-sm sm:text-lg mb-0 text-secondary-500 dark:text-secondary-200">Kies een index</h4>
          </div>
        </div>
      </template>

      <div>
        <p class="text-xs sm:text-sm text-gray-500 mb-4">De <i>Search Engine</i> van het Onderwijsloket in opgebouwd uit <code>indices</code>. Elke <code>index</code> is doorzoekbaar, en heeft zijn eigen statistieken.</p>
        <p class="text-xs sm:text-sm text-gray-500 mb-4">Een index is een dataset die bestaat uit <i>records</i> (ofwel <i>items</i>, <i>entries</i>, <i>regels</i>, etc.). Meestal staat een index gelijk aan een bepaald content soort (zoals <code>artikelen</code> of <code>opleidingen</code>), maar soms is het een bundeling van meerdere contentsoorten binnen één index.</p>
        <div class="h-12" />
        <div v-if="!!indices?.length" class="absolute -translate-y-12">
          <USelectMenu
            v-model="index"
            :options="indices" 
            value-attribute="key" 
            class="index-select"
            :ui="{
              option: {
                base: 'gap-10',
                selected: 'bg-gray-50'
              }
            }"
          >
            <UButton 
              color="white" 
              size="xl"
              icon="i-mdi-database-search"
              :ui="{
                color: { 
                  white: { 
                    solid: 'text-primary-400 hover:text-primary-500'
                  }
                },
              
              }"
            >
              <span class="text-2xs">Huidige index: {{ selectedIndex?.name }}</span>
            </UButton>
            <template #option="{ option }">
              <div class="w-full">
                <p class="text-sm font-bold">{{ option.name }}</p>
                <p class="text-xs text-gray-600 dark:text-gray-400">{{ option.description }}</p>
                <div v-if="!!option.children?.length" class="mt-2 flex items-start flex-wrap gap-2">
                  <UBadge 
                    v-for="child in option.children" 
                    color="secondary"
                    size="2xs"
                    variant="subtle"
                    class="text-[8px] leading-3"
                    :label="indices.find((i: any) => i.key === child)?.name"
                  />
                </div>
              </div>
              
              
            </template>

            <template #empty>
              Geen indices gevonden...
            </template>

          </USelectMenu>
        </div>
        
      </div>
    </UCard>

    <Stat
      title="Totaal zoekopdrachten"
      :hint="total_searches"
      :count="indexStats?.numberOfSearches.count"
      class="md:col-span-6 md:row-span-1"
      icon="i-heroicons-document-magnifying-glass-20-solid"
      :error="indexError"
      :trend="indexStats?.numberOfSearches.trend"
      :loading="loading"
    />
  </div>
  

  <TopSearchQueries 
    :index="index"
    class="md:col-span-6 "
  />
  
  <Searches 
    :index="indices?.find((i: any) => i.key === index)?.name"
    :data="chartData" 
    :trend="indexStats?.numberOfSearches.trend"
    :loading="loading"
    :error="indexError"
    class="md:col-span-12"
  />

</template>

<script lang="ts" setup>

const { getDateRangeParam, dateRange, compareBy, getGroupByParam } = useStatsQuery()
const { total_searches } = useStatsHints().platform

const { app } = useApps()

const { $api } = useNuxtApp()
const { data: indices, status: searchStatus, error: searchError }: any = useLazyAsyncData(async () => {
  const res = await $api('/stats/platform/search')
  return res
}, {
  server: false
})

const isLoadingIndices = computed(() => searchStatus.value !== 'success' && searchStatus.value !== 'error')
const loadingIndices = refDebounced(isLoadingIndices, 200)


const index = ref('global')

const selectedIndex = computed(() => indices.value?.find((i: any) => i.key === index.value))

const { data: indexStats, status: indexStatus, error: indexError, refresh: indexRefresh }: any = useLazyAsyncData(async () => {
  const res = await $api('/stats/platform/search/' + index.value, {
      query: {
        period: 'custom',
        date: getDateRangeParam(),
        includeTrends: compareBy.value,
        groupBy: getGroupByParam()
      }
  })
  return res
}, {
  server: false
})


const isLoading = computed(() => indexStatus.value !== 'success' && indexStatus.value !== 'error')
const loading = refDebounced(isLoading, 200)

watch([dateRange, app, compareBy, index], async () => {
  await indexRefresh()
})


const chartData = computed(() => ({
  labels: !indexStats.value ? [] : Object.keys(indexStats.value?.numberOfSearches.series),
  datasets: [
    {
      label: 'Zoekopdrachten',
      data: !indexStats.value ? [] : Object.values(indexStats.value?.numberOfSearches.series).map((val: any) => val.count),
    },
  ],
}))


</script>

<style lang="postcss">
.index-select {
  .select-menu {
    @apply w-[calc(100vw-48px)] md:w-[600px];

    li[role="option"] {
      @apply mb-2
    }
  }
}
</style>