<template>
  <div class="h-full sm:h-auto">
    <UPopover :popper="{ placement: 'bottom-start' }" :ui="{wrapper: 'h-full sm:h-auto', trigger: 'h-full sm:h-auto'}">
    <UButton icon="i-heroicons-calendar-days-20-solid" color="white" size="xl" :ui="{color: { white: { solid: 'text-primary-400 hover:text-primary-500'}}}">
      <span class="text-xs hidden sm:inline">{{ format(dateRange.start, 'dd/MM/yy') }} - {{ format(dateRange.end, 'dd/MM/yy') }}</span>
    </UButton>

    <template #panel="{ close }">
      <div class="flex items-center sm:divide-x divide-gray-200 dark:divide-gray-800">
        <div class="hidden sm:flex flex-col py-4">
          <UButton
            v-for="(range, index) in ranges"
            :key="index"
            :label="range.label"
            variant="ghost"
            class="rounded-none px-6 leading-normal"
            color="gray"
            :class="[isRangeSelected(range.duration) ? 'bg-gray-100 dark:bg-gray-800' : 'hover:bg-gray-50 dark:hover:bg-gray-800/50']"
            truncate
            @click="selectRange(range.duration)"
          />
        </div>

        <DatePicker v-model="dateRange" @close="close" />
      </div>
    </template>
  </UPopover>
  </div>
</template>

<script lang="ts" setup>
import { sub, format, isSameDay, type Duration, subMonths } from 'date-fns'
type Ranges = {
    label: string,
    duration: Duration
}[]

type DateRange = { start: Date, end: Date }

const props = withDefaults(defineProps<{
  modelValue: DateRange
  ranges?: Ranges
}>(), {
  ranges: () => [
    { label: 'Vandaag', duration: { days: 0 } },
    { label: 'Gisteren', duration: { days: -1 } },
    { label: 'Laatste week', duration: { days: 7 } },
    { label: 'Laatste maand', duration: { months: 1 } },
    { label: 'Laaste jaar', duration: { years: 1 } },
    { label: 'Alle gegevens', duration: { alltime: true } }
  ] as Ranges
})


const emit = defineEmits(['update:model-value'])

const dateRange = computed({
  get: () => props.modelValue,
  set: (value) => {
    emit('update:model-value', value)
  }
})

function isRangeSelected (duration: Duration) {
  return isSameDay(dateRange.value.start, sub(new Date(), duration)) && isSameDay(dateRange.value.end, new Date())
}

function selectRange (duration: Duration & { alltime?: boolean }) {
  if (duration.alltime) {
    const val = { start: new Date('2019-01-01'), end: new Date() }
    dateRange.value = val
  } else if (duration.days === -1) {
    const val = { start: sub(new Date(), { days: 1 }), end: sub(new Date(), { days: 1 }) }
    dateRange.value = val
  } else {
    const val = { start: sub(new Date(), duration), end: new Date() }
    dateRange.value = val
  }
  
}

</script>
