<template>
  <IconCard
    :ui="{
      ring: 'ring-1 ring-secondary-200 dark:ring-gray-600',
    }"
    color="secondary"
    icon="i-heroicons-cursor-arrow-ripple-20-solid"
    class="md:col-span-5"
  >
  <template #header>
    
      <div class="flex flex-col sm:flex-row gap-y-2 pt-1 sm:pt-0 gap-x-4 sm:items-center grow ">
        <h4 class="text-sm sm:text-lg mb-0 text-secondary-500 dark:text-secondary-200">Apps van het Onderwijsloket</h4>
      </div>
  </template>

  <div>
    <p class="text-xs sm:text-sm text-gray-500 dark:text-gray-300 mb-4">Het platform van het Onderwijsloket bestaat uit verschillende apps. Kies één van de onderstaande apps om de gebruiksstatistieken ervan in te zien.</p>
    <UTabs 
      v-model="selectedApp" 
      :items="apps" 
      orientation="vertical"
      class="-mx-3"
      :ui="{
        container: 'w-auto',
        list: {
          background: 'bg-transparant dark:bg-transparant',
          marker: {
            base: 'w-auto',
            background: 'bg-secondary-50/50 dark:bg-secondary-900/30 ring-1 ring-secondary-100 dark:ring-gray-600'
          },
          tab: {
            base: 'justify-start w-auto my-1',
            size: 'text-2xs sm:text-sm',
            font: 'font-bold'
          }
        }
      }"
    >
    <template #icon="{ item, selected }">
      <NuxtImg 
        :src="item.appIcon"
        width="20"
        height="20"
        sizes="40px"
      />
    </template>
    <template #default="{ item, index, selected }">
      <span class="truncate ml-2" :class="[selected && 'text-gray-800 dark:text-gray-200']">{{ item.label }}</span>
    </template>

    </UTabs>
  </div>
  </IconCard>
  
  <DashboardCard
    icon="i-heroicons-information-circle-20-solid"
    title="Over deze app"
    class="md:col-span-7"
  >
    <div>
      <p class="text-xs sm:text-sm text-gray-500 dark:text-gray-300 mb-4">{{  app.description }}</p>
      <div class="flex justify-between items-center flex-wrap gap-x-4 gap-y-4">
        <div class="flex gap-1 sm:gap-3 items-center">
          <NuxtImg 
            :src="app.appIcon"
            width="20"
            height="20"
            sizes="40px"
          />
          <span class="font-bold text-xs sm:text-sm md:text-base" >{{ app.label }}</span>
        </div>
        <UButton 
          :to="app.url"
          target="_blank"
          label="bezoek app"
          icon="i-heroicons-arrow-up-right-20-solid"
          trailing
          variant="soft"
          size="sm"
        />
      </div>
      <div v-if="app.demo" class="mt-4">
        <UBadge color="gray">
          <UIcon name="i-heroicons-cog-8-tooth-20-solid" />
          <span class="ml-2">deze app is een demo</span>
        </UBadge>
      </div>
    </div>
  </DashboardCard>

  <RealtimeVisitors 
    :app="app.label"
    class="md:col-span-6 xl:col-span-3"
  />
  <Stat
      title="Totaal bezoekers"
      :hint="total_visitors"
      :count="data?.platform.aggregate.visitors"
      format-count
      :trend="data?.platform.trends?.visitors"
      icon="i-heroicons-user-group-20-solid"
      class="md:col-span-6 xl:col-span-3"
      :loading="loading"
  />
  <Stat
      title="Totaal sessies"
      :hint="total_visits"
      :count="data?.platform.aggregate.visits"
      format-count
      :trend="data?.platform.trends?.visits"
      icon="i-heroicons-computer-desktop-20-solid"
      class="md:col-span-6 xl:col-span-3"
      :loading="loading"
  />
  <Stat
      title="Totaal weergaven"
      :hint="total_pageviews"
      :count="data?.platform.aggregate.pageviews"
      format-count
      :trend="data?.platform.trends?.pageviews"
      icon="i-heroicons-eye-20-solid"
      class="md:col-span-6 xl:col-span-3"
      :loading="loading"
  />

  <PlausibleTimeseries 
    :data="data?.platform.series || null"
    :loading="loading"
    :trends="data?.platform.trends"
    :error="!!error"
    :app="app.label"
    @retry="refresh"
    class="md:col-span-12"
  />  
  <Sources class="md:col-span-6" />
  <Pages class="md:col-span-6" />
  <Regions class="md:col-span-6" />
  <Devices class="md:col-span-6" />


<Notice 
    title="Ontdek alle gebruiksstatistieken"
    :description="`Meer gebruiksstatistieken over ${app.label} ontdekken? Via het embedded Plausible dashboard heb je toegang tot alle analyticsdata van deze app, uitgebereide filters en segmenten, en méér vergelijkingsopties.`"
    :to="`/plausible/?app=${app.label}`"
    label="bekijk het uitgebreide dashboard"
    class="md:col-span-12 mb-6"
    hide-close
/>

<SubSection label="API van het Onderwijsloket">
  <DashboardGrid>
    <Stat
      title="Totaal API gebruikers"
      :hint="api_users"
      :count="data?.api?.apiUsers"
      class="md:col-span-6"
      icon="i-heroicons-key-20-solid"
      :trend="data?.api?.trends?.apiUsers"
      :loading="loading"
    />
    <Stat
      title="Totaal API verzoeken"
      :hint="api_requests"
      :count="data?.api?.totalRequests"
      class="md:col-span-6"
      icon="i-heroicons-code-bracket-20-solid"
      :trend="data?.api?.trends?.totalRequests"
      :loading="loading"
    />
    <Notice 
      title="Over onze API"
      :description="api"
      to="/api"
      label="over onze api"
      class="md:col-span-8"
      @close="apiNoticeHidden = true"
    />
    <ClientOnly>
      <ApiPlayground class="" :class="!apiNoticeHidden ? 'md:col-span-4' : 'md:col-span-12'" :hide-image="grid.md && !apiNoticeHidden" />
    </ClientOnly>
    
  </DashboardGrid>
  
</SubSection>


<SubSection label="Search engine van het Onderwijsloket">
  <DashboardGrid>
    <SearchEngine />
    <Notice 
      title="Over onze search engine"
      :description="searchEngine"
      to="/search"
      label="over onze search engine"
      class="md:col-span-12"
    />
  </DashboardGrid>
  
</SubSection>

</template>

<script setup lang="ts">
// Examples https://vue-chartjs.org/examples/

const { getDateRangeParam, dateRange, compareBy, getGroupByParam } = useStatsQuery()
const { searchEngine, api } = useStatsHints().platform

const { apps, app, selectedApp } = useApps()

const {
  total_visitors,
  total_visits,
  total_pageviews,
  api_users,
  api_requests
} = useStatsHints().platform

const { grid } = useResponsive()

const { $api } = useNuxtApp()
const { data, status, error, refresh, clear }: any = useLazyAsyncData(async () => {
  const res = await $api('/stats/platform', {
    query: {
      period: 'custom',
      date: getDateRangeParam(),
      includeTrends: compareBy.value,
      includeApi: true,
      includeSearch: false,
      app: app.value.label,
      groupBy: getGroupByParam()
    }
  })
  return res
}, {
  server: false
})


const isLoading = computed(() => status.value !== 'success' && status.value !== 'error')
const loading = refDebounced(isLoading, 200)

watch([dateRange, app, compareBy], async () => {
  await refresh()
})

const apiNoticeHidden = ref(false)
</script>