<template>
  <DashboardCard
    icon="i-heroicons-magnifying-glass-20-solid"
    :title="`Zoekopdrachten voor '${index} index'`"
    :loading="loading"
    :height="400"
    :trend="trend"
    :hint="searches"
    :empty="isEmpty"
    :empty-hint="emptyHint"
    :error="!!error"
    @retry="emit('retry')"
  >
    <Line :data="data" :options="chartOptions"/>
  </DashboardCard>
  
</template>

<script setup lang="ts">
type DataSet = {
  label: string
  data: number[]
}
const props = defineProps<{
  loading: boolean
  data: {
    labels: string[],
    datasets: DataSet[]
  }
  index?: string
  trend?: number | 'infinite'
  error?: any
}>()

const emit = defineEmits<{
  (e: 'retry'): void
}>()

const { 
  searches,
} = useStatsHints().platform

const { 
  algolia,
} = useStatsHints().empty

const {
    Line,
    baseLineOptions
} = useCharts()

const { dateRange } = useStatsQuery()

const isEmpty = computed(() => !props.data.datasets[0]?.data.length || props.data.datasets[0]?.data.reduce((acc, cur) => acc + cur,0) === 0)
const emptyHint = computed(() => {
  if (dateRange.value.end < new Date(algolia.date)) return algolia.text
})

const chartOptions: Ref<any> = ref(baseLineOptions)
</script>