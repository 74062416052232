<template>
  <ListCard
    :icon="icon"
    :title="title"
    :loading="loading"
    :hint="episode_rankings"
    :has-initial-data="!loading"
    :empty="isEmpty"
    :empty-hint="emptyHint"
    :error="!!error"
    show-more-label="toon meer afleveringen"
    @retry="emit('retry')"
    @show-more="showMore = true"
  >

    <Episode v-for="source in firstSet" :data="source" />

    <template #details>
      <Episode v-for="source in data" :data="source" />
    </template>

  </ListCard>
  
</template>

<script setup lang="ts">
const props = defineProps<{
  data?: any[]
  loading?: boolean
  error?: boolean,
}>()

const { dateRange } = useStatsQuery()

const showMore = ref(false)

const icon = 'i-heroicons-presentation-chart-bar-20-solid'
const title = "Episode ranking"


const { 
  episode_rankings,
} = useStatsHints().media


const { 
  spotify,
} = useStatsHints().empty

const emit = defineEmits<{
  (e: 'retry'): void
}>()


const isEmpty = computed(() => !props.data?.length)
const emptyHint = computed(() => {
  if (spotify && dateRange.value.end < new Date(spotify.date)) return spotify.text
})

const firstSet = computed(() => {
  if (!props.data) return []
  return props.data.slice(0, 10)
})



</script>