<template>
  <div>
    <UButton 
      class="mt-4 -mx-2.5"
      icon="i-heroicons-magnifying-glass-plus-20-solid"
      :label="showMoreLabel || ('toon meer ' + title.toLowerCase())"
      color="gray"
      variant="ghost"
      @click="showMore = true"
      />

      <UModal 
        v-model="showMore"
        :fullscreen="!grid.sm"
        :ui="{
          width: 'sm:max-w-[75vw]',
          overlay: {
            background: 'dark:bg-gray-950/75'
          }
        }"
      >
        <UCard 
          :ui="{ 
            base: 'sm:max-h-[90dvh] h-full',
            
            ring: '', 
            divide: 'divide-y divide-gray-100 dark:divide-gray-700',
            shadow: 'shadow-none',
            header: {
              padding: 'pt-4 pb-4'
            },
            footer: {
              padding: 'pt-6'
            },
            body: {
              base: 'overflow-y-scroll'
            }
        }"
        >
          <template #header>
            <div class="flex items-center justify-between">
              <div class="flex items-center gap-2">
                <div v-if="icon" class="w-7 h-7 sm:h-8 sm:w-8 rounded-md bg-gray-100 dark:bg-gray-800 grid place-items-center shrink-0">
                  <UIcon :name="icon" class="w-4 h-4 text-gray-600 dark:text-gray-400" />
                </div>
                <div class="flex flex-col sm:flex-row gap-y-2 pt-1 sm:pt-0 gap-x-4 sm:items-center grow ">
                  <h4 class="text-sm sm:text-lg mb-0 text-gray-600 dark:text-gray-400">{{title}}</h4>
                </div>
              </div>
              <UButton color="gray" variant="ghost" icon="i-heroicons-x-mark-20-solid" class="-my-1" @click="showMore = false" />
            </div>
            
          </template>

          <slot />

          <template #footer>
            <div class="w-full flex flex-row justify-end gap-3">
              <UButton variant="soft" color="gray" @click="showMore = false">Sluit</UButton>
            </div>
          </template>
        </UCard>
      </UModal>
  </div>
</template>

<script lang="ts" setup>
const props = defineProps<{
  modelValue: boolean,
  title: string
  icon?: string
  showMoreLabel?: string
}>()


const emit = defineEmits<{
  (e: 'update:modelValue', value: boolean): void
}>()


const showMore = computed({
  get() {
    return props.modelValue
  },
  set(value: boolean) {
    emit('update:modelValue', value)
  }
})

const { grid } = useResponsive()
</script> 

<style>

</style>