<template>
  <SubSection label="Podcasts van het Onderwijsloket">
    <DashboardGrid>
      <IconCard
        :ui="{
          ring: 'ring-1 ring-secondary-200 dark:ring-gray-600',
         
        }"
        color="secondary"
        icon="i-heroicons-microphone-20-solid"
        class="md:col-span-5"
      >
      <template #header>
          <div class="flex flex-col sm:flex-row gap-y-2 pt-1 sm:pt-0 gap-x-4 sm:items-center grow ">
            <h4 class="text-sm sm:text-lg mb-0 text-secondary-500 dark:text-secondary-200">Kies een podcast</h4>
          </div>
      </template>
      <div>
        <p class="text-xs sm:text-sm text-gray-500 dark:text-gray-300 mb-4">Het Onderwijsloket produceert meerdere podcast shows. Kies voor welke show je de statistieken wilt zien.</p>
        <UTabs 
          v-model="selectedShow" 
          :items="shows" 
          orientation="vertical"
          class="-mx-3"
          :ui="{
            container: 'w-auto',
            list: {
              background: 'bg-transparant dark:bg-transparant',
              marker: {
                base: 'w-auto',
                background: 'bg-secondary-50/50 dark:bg-secondary-900/30 ring-1 ring-secondary-100 dark:ring-gray-600'
              },
              tab: {
                base: 'justify-start w-full my-1 py-2',
                size: 'text-2xs sm:text-sm',
                font: 'font-bold',
                height: 'h-auto'
              }
            }
          }"
        >
        <template #icon="{ item, selected }">
          <NuxtImg 
            :src="item.public_id"
            width="80"
            height="80"
            sizes="120px"
            class="object-fit object-center h-12 w-12 rounded-lg"

          />
        </template>
        <template #default="{ item, index, selected }">
          <div class="text-left ml-4 ">
            <h5 class="truncate text-sm mb-0" :class="[selected && 'text-gray-800 dark:text-gray-200']">{{ item.label }}</h5>
            <p class="text-xs font-normal">{{ item.descriptionShort }}</p>
          </div>
          
        </template>

        </UTabs>
      </div>

     
      </IconCard>

      <DashboardCard
        icon="i-heroicons-information-circle-20-solid"
        :title="'Over ' + show.label"
        class="md:col-span-7"
      >
        <div>
          <p class="text-xs sm:text-sm text-gray-500 dark:text-gray-300 mb-4">{{  show.description }}</p>
          <div class="flex justify-between items-center flex-wrap gap-x-4 gap-y-4">
            <UButton 
              :to="show.url"
              target="_blank"
              label="luister op Spotify"
              icon="i-mdi-spotify"
              variant="soft"
              size="sm"
            />
          </div>
        </div>
      </DashboardCard>
      
      <Stat
        title="Totaal geluisterde afleveringen"
        :hint="total_listens"
        :count="data?.aggregate.totalPlays"
        format-count
        :trend=data?.trends?.totalPlays
        icon="i-heroicons-speaker-wave-20-solid"
        class="md:col-span-6"
        :loading="loading"
      />
      <Stat
          title="Aantal afleveringen"
          :hint="total_episodes"
          :count="data?.episodeRankings.length"
          :trend="episodesGrowth"
          is-absolute-trend
          format-count
          icon="i-heroicons-archive-box-20-solid"
          class="md:col-span-6"
          :loading="loading"
      />

      <TopEpisode 
        :data="data?.episodeRankings[0]"
        :show="show"

        class="md:col-span-6"
      />

      <EpisodeRanking 
        :data="data?.episodeRankings"
        :loading="isLoading"
        :error="error"
        class="md:col-span-6"
      />

      <DashboardCard
        icon="i-heroicons-speaker-wave-20-solid"
        title="Geluisterde afleveringen"
        :loading="loading"
        :height="400"
        :trend="data?.trends?.totalPlays"
        :hint="daily_listens"
        :empty="isEmpty"
        :empty-hint="emptyHint"
        :error="!!error"
        class="md:col-span-12"
        @retry="refresh"
      >
        <Line :data="chartData" :options="chartOptions"/>
      </DashboardCard>


      <Notice 
        title="Kantekeningen bij de podcast statistieken"
        :description="`Het aantal plays (geluisterde afleveringen) van onze podcasts worden gebaseerd op de analytics data van Spotify in combinatie met de analyticsdata van onze eigen webplayer. Luisteraars via andere kanalen, zoals Apple Podcasts, zijn niet in de de statistieken meergenomen. De statistieken worden periodiek bijgewerkt (circa 1x per maand), dus het kan zijn dat de meest recente data ontbreekt.`"
        class="md:col-span-12"
      />
    </DashboardGrid>
  </SubSection>
  

<SubSection label="Video's van het Onderwijsloket">
  <DashboardGrid>
    <Notice 
      title="Gebruikstatistieken van onze videocontent zijn nog niet beschikbaar"
      :description="`Op dut moment kunnen de we weergaven en andere statistieken van onze video's nog niet delen. We hopen dit op korte termijn aan het dashboard toe te kunnen voegen!`"
      class="md:col-span-12"
    />
    
  </DashboardGrid>
  
</SubSection>


</template>

<script setup lang="ts">

const { getDateRangeParam, dateRange, compareBy, getGroupByParam } = useStatsQuery()

const {
  total_episodes,
  total_listens,
  daily_listens
} = useStatsHints().media


const { show, selectedShow, shows } = useShows()
 

const { $api } = useNuxtApp()
const { data, status, error, refresh, clear }: any = useLazyAsyncData(async () => {
  const res = await $api('/stats/media/podcasts', {
    query: {
      period: 'custom',
      date: getDateRangeParam(),
      includeTrends: compareBy.value,
      show: show.value.key,
      groupBy: getGroupByParam()
    }
  })
  return res
}, {
  server: false
})


const episodesGrowth = computed(() => {
  if (compareBy.value === 'none' || !data.value) return undefined

  return data.value.episodeRankings.filter((rec: any) => new Date(rec.publishDate).getTime() >= dateRange.value.start.getTime()).length
})

const isLoading = computed(() => status.value !== 'success' && status.value !== 'error')
const loading = refDebounced(isLoading, 200)

watch([dateRange, show, compareBy], async () => {
  await refresh()
})

const { 
  spotify,
} = useStatsHints().empty

const {
    Line,
    baseLineOptions
} = useCharts()


const chartData = computed(() => ({
  labels: !data.value ? [] : Object.keys(data.value?.series),
  datasets: [
    {
      label: 'Plays',
      data: !data.value?.series ? [] : Object.values(data.value.series).map((val: any) => val.plays),
    },
  ],
}))

const isEmpty = computed(() => !chartData.value?.datasets[0]?.data.length || chartData.value.datasets[0]?.data.reduce((acc, cur) => acc + cur,0) === 0)
const emptyHint = computed(() => {
  if (dateRange.value.end < new Date(spotify.date)) return spotify.text
})

const chartOptions: Ref<any> = ref(baseLineOptions)
</script>