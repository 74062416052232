<template>
  <UCard
    :ui="{
      base: 'grid',
      background: 'bg-white dark:bg-gray-700/50',
      ring: !!error ? 'ring-1 ring-error-500 dark:ring-error-500' : 'ring-1 ring-secondary-200 dark:ring-gray-600',
      body: {
        padding: 'sm:p-4 lg:p-6'
      }
    }"
  >
    <div class="h-full flex flex-col items-start gap-4 justify-between">
      <UBadge size="xs" color="primary" :variant="isDark ? 'subtle' : 'solid'">
        <UIcon name="i-heroicons-star-20-solid" />
        <span class="ml-1">Best beluisterde aflevering</span>
      </UBadge>
      <div class="grow w-full">
        <div class="flex flex-col sm:flex-row gap-y-3 gap-x-6 items-start sm:items-center w-full">
          <USkeleton 
            v-if="loading"
            class="shrink-0 h-24 w-24 rounded-lg "
          />
          <NuxtImg 
            v-else
            :src="show.public_id"
            sizes="120px"
            class="h-24 w-24 rounded-lg object-center shrink-0 bg-gray-50 dark:bg-gray-700"
          />
          <div class="w-full">
            <div v-if="loading" class="space-y-2 mb-4">
              <USkeleton 
                class="h-4 w-full"

              />
              <USkeleton 
                class="h-4 w-3/5"
              />
            </div>
            <h5 v-else class="text-base font-bold mb-2">{{ name }}</h5>
            <div v-if="loading" class="flex gap-2 flex-wrap">
              <USkeleton 
                v-for="i in 3"
                class="h-5 w-16"
              />
            </div>
            <div v-else class="flex gap-2 flex-wrap">
              <UBadge size="xs" color="secondary" variant="subtle">
                <UIcon name="i-heroicons-microphone-20-solid" />
                <span class="ml-1">{{ show.label }}</span>
              </UBadge>
              <UBadge v-if="code" size="xs" color="gray" variant="subtle">
                {{ code }}
              </UBadge>
              <UBadge v-if="duration" size="xs" color="gray" variant="subtle">
                <UIcon name="i-heroicons-clock-20-solid" class="mr-1" />
                <span>{{ duration }} min</span>
              </UBadge>
            </div>
            
          </div>
        </div>
        <div v-if="loading" class="mt-6">
          <USkeleton 
            v-for="i in 7"
            class="h-3 w-full"
            :class="i === 5 ? 'mb-8' : 'mb-3'"
          />
          <USkeleton 
            class="h-3 w-3/5"
          />
        </div>
        <div 
          v-else
          v-html="episode?.html_description" 
          class="mt-3 text-xs line-clamp-10 inner-content dark:text-gray-300" 
        />
      </div>
      <div>
        <USkeleton v-if="loading" class="h-8 w-32"/>
        <UButton
          v-else-if="url"
          :to="url"
          target="_blank"
          color="secondary"
          variant="ghost"
          size="sm"
          icon="i-mdi-spotify"
          class="-mx-2"
        >
          beluister aflevering
        </UButton>
      </div>
      

    </div>

  </UCard>
  
</template>

<script setup lang="ts">
import { isSameDay } from 'date-fns';

import type { Show } from '~~/types'

const props = withDefaults(defineProps<{
  show: Show
  data?: {
    id: number;
    externalId: string;
    name: string;
    season: number | null;
    isTrailer: boolean;
    publishDate: string;
    plays: number;
    podcastShow: string;
    updatedAt: string;
    ranking: number;
}
}>(), {

})


const { show, selectedShow, shows } = useShows()
const { $api } = useNuxtApp()
const { data, status, error, refresh, clear }: any = useLazyAsyncData(async () => {
  const res = await $api('/media/podcasts/episodes', {
    query: {
      show: [show.value.label]
    }
  })
  return res
}, {
  server: false
})

watch([show], async () => {
  await refresh()
})

const { isDark } = useTheme()


const loading = computed(() => status.value !== 'success' && status.value !== 'error')
// const loading = refDebounced(isLoading, 200)


const episode = computed(() => {
  if (!data.value || !data.value[0]) return null
  const episodeWithSameName = data.value[0].items.find((ep: any) => ep.name.toLowerCase() === props.data?.name.toLowerCase())
  if (!!episodeWithSameName) return episodeWithSameName
  else if (!!props.data) return data.value[0].items.find((ep: any) => !!props.data && isSameDay(new Date(ep.release_date), new Date(props.data.publishDate)))
  return null
})

const { extractEpisodeCodes } = useShows()

const name = computed(() => {
  if (!episode.value) return null
  return extractEpisodeCodes(episode.value.name).title
})

const code = computed(() => {
  if (!props.data) return null
  return extractEpisodeCodes(props.data.name).code
})

const url = computed(() => {
  return episode.value?.external_urls.spotify || ''
})

const duration = computed(() => {
  if (!episode.value) return null
  return Math.ceil(episode.value?.duration_ms / 1000 / 60)
})

</script>

<style lang="postcss">
.inner-content {
  a {
    @apply underline;
  }
}
</style>