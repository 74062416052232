<template>
  <ListCard
    :icon="icon"
    :title="title"
    :loading="isLoading"
    :has-initial-data="!!firstSet.length"
    :hint="sources"
    :empty="isEmpty"
    :empty-hint="emptyHint"
    :error="!!error"
    @retry="refresh"
    @show-more="showMore = true"
  >
    <Source v-for="source in firstSet" :data="source" />

     <template #details>
        <Source v-for="source in data" :data="source" />
     </template>


  </ListCard>
  
</template>

<script setup lang="ts">
const { getDateRangeParam, dateRange } = useStatsQuery()

const { app } = useApps()
const { $api } = useNuxtApp()
const showMore = ref(false)

const icon = 'i-heroicons-link-20-solid'
const title = 'Bronnen'


const { data, status, error, refresh, clear }: any = useLazyAsyncData(async () => {
  const res: any = await $api('/stats/platform/breakdown', {
    query: {
      period: 'custom',
      date: getDateRangeParam(),
      app: app.value.label,
      prop: 'sources',
      limit: showMore.value ? 100 : 10
    }
  })
  return res
}, {
  server: false
})


const isLoading = computed(() => status.value !== 'success' && status.value !== 'error')

watch([dateRange, app, showMore], async () => {
  await refresh()
})

const { 
  sources,
} = useStatsHints().platform


const { 
  plausible,
} = useStatsHints().empty


const isEmpty = computed(() => !data.value?.length)
const emptyHint = computed(() => {
  if (plausible[app.value.label] && dateRange.value.end < new Date(plausible[app.value.label].date)) return plausible[app.value.label].text
})

const firstSet = computed(() => {
  if (!data.value) return []
  return data.value.slice(0, 10)
})



</script>