<template>
  <SubSection label="Tools van het Onderwijsloket">
    <DashboardGrid gap="sm">
      <Tool
        v-for="tool in tools"
        :data="tool"
        class="md:col-span-6"
        :loading="loading"
        :error="error"
      />
    </DashboardGrid>

  </SubSection>
  <SubSection label="Content van het Onderwijsloket">
    <ContentTypes 
      :data="data?.content"
      :loading="loading"
      :error="error"
    />
  </SubSection>
  
</template>

<script setup lang="ts">
// Examples https://vue-chartjs.org/examples/

type ContentCount = {
  content: Record<string, {
    count: number,
    url?: string
    description: string
  }>
  tools: {
    name: string
    description: string
    demo: boolean
    url: string
  }[]
}
const { $api } = useNuxtApp()
const { getDateRangeParam, dateRange, compareBy, getGroupByParam } = useStatsQuery()
const { data, status, error, refresh, clear }: any = useLazyAsyncData(async () => {
  const res: ContentCount = await $api('/stats/content', {
    query: {
      period: 'custom',
      date: getDateRangeParam(),
      includeTrends: compareBy.value,
      groupBy: getGroupByParam()
    }
  })
  return res
}, {
  server: false
})

watch([dateRange, compareBy], async () => {
  await refresh()
})

const { 
  content
} = useStatsHints()

const isLoading = computed(() => status.value !== 'success' && status.value !== 'error')
const loading = refDebounced(isLoading, 200)

const tools = computed(() => {
  if (!data.value) return Array(6).fill({
      name: '',
      description: '',
      demo: false,
      url: undefined,
  });
  return data.value.tools
})




</script>