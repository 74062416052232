<template>
  <DashboardCard
    icon="i-heroicons-device-phone-mobile-20-solid"
    title="Gebruikte devices"
    :loading="loading"
    :height="240"
    :hint="devices"
    :empty="isEmpty"
    :empty-hint="emptyHint"
    :error="!!error"
    @retry="refresh"
  >
      <Pie
        :data="pieData"
        :options="chartOptions"
      />
  </DashboardCard>
  
</template>

<script setup lang="ts">
const { getDateRangeParam, dateRange, compareBy } = useStatsQuery()

const { apps, app, selectedApp } = useApps()
const { $api } = useNuxtApp()

const { data, status, error, refresh, clear }: any = useLazyAsyncData(async () => {
  const res: any = await $api('/stats/platform/breakdown', {
    query: {
      period: 'custom',
      date: getDateRangeParam(),
      app: app.value.label,
      prop: 'devices'
    }
  })
  return res
}, {
  server: false
})


const isLoading = computed(() => status.value !== 'success' && status.value !== 'error')
const loading = refDebounced(isLoading, 200)

watch([dateRange, app, compareBy], async () => {
  await refresh()
})

const { 
  devices,
} = useStatsHints().platform

const {
  Pie,
  basePieOptions
} = useCharts()


const { 
  plausible,
} = useStatsHints().empty


const emptyHint = computed(() => {
  if (plausible[app.value.label] && dateRange.value.end < new Date(plausible[app.value.label].date)) return plausible[app.value.label].text
})

const { colorList } = useCharts()

const deviceMap = {
  Mobile: 'Mobiel',
  '(not set)': 'Onbekend'
}

const pieData = computed(() => {
  // @ts-expect-error
  let labels: string[] = !data.value ? [] : data.value.map((rec: any) => deviceMap[rec.device] || rec.device as string)
  const backgroundColor = labels.map((_, index) => colorList.value[index % colorList.value.length] as string);

  return {
    labels,
    datasets: [
      {
        label: 'Bezoekers',
        backgroundColor,
        data: !data.value ? [] : data.value.map((rec: any) => rec.visitors) as number[] ,
      },
    ],
  }
})

const isEmpty = computed(() => !pieData.value || !pieData.value.datasets[0]?.data.length || pieData.value.datasets[0]?.data.reduce((acc, cur) => acc + cur,0) === 0)

const chartOptions = ref(basePieOptions)
</script>