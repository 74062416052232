<template>
  <li class="flex flex-row items-start gap-3 justify-start">
    <UIcon  name="i-heroicons-link-20-solid" class="w-5 h-5 text-gray-500 shrink-0" />
    <UPopover mode="hover"  class="text-sm hover:underline truncate  text-gray-400 dark:text-gray-500">
      <NuxtLink v-if="!!link" :to="link" target="_blank" class="grow text-sm hover:underline truncate  text-gray-400 dark:text-gray-400 mr-3">{{ data.page }}</NuxtLink>
      <template #panel>
        <div class="p-2">
          <NuxtLink :to="link" target="_blank" class="text-2xs hover:underline">{{ data.page }}</NuxtLink>
        </div>
      </template>
    </UPopover>
    
    <span class="text-sm font-bold shrink-0 ml-auto justify-end">{{ count }}</span>
  </li>
</template>

<script lang="ts" setup>
const props = defineProps<{
  data: {
    page: string
    visitors: number
  }
  noTruncate?: boolean
}>()

const { app } = useApps()


const link = computed(() => {
  try {
      return 'https://' + app.value.label + props.data.page
    } catch {
      return ''
    }
})


const count = computed(() => formatNumber(props.data.visitors))
</script>

<style>

</style>