export const useStatsHints = () => {
    const advies = {
        totaal_adviesvragers: "Het aatal mensen dat is geholpen door onze adviseurs in de gekozen periode. Deze mensen zijn geholpen via de email, telefonisch of via ingeplande adviesgesprekken.",
        terugkerend: "Het percentage van de geholpen adviesvragers die het Onderwijsloket al eerder voor hulp hebben benaderd.",
        totaal_gepland: "Het aantal in ingeplande adviesgesprekken in de gekozen periode. Adviesgesprekken kunnen gepland worden via de boekingstool van het Onderwijsloket, en nemen 20 minuten in beslag.",
        geplande_gesprekken_naar_sector: "Over welke onderwijssectoren de adviesvragers het willen hebben tijdens hun geplande adviesgesprek. Er kunnen meerdere sectoren per adviesgesprek worden opgegeven.",
        gepland_canceled: "Het percentage ingeplande adviesgesprekken dat wordt geannuleerd.",

    }

    const content = {
        dateRange: 'Op dit moment is het niet mogelijk om contentaantallen (en trends daarbinnen) uit te filteren op basis van de door jou gekozen datum.'
    }

    const platform = {
        searchEngine: "De gebruiksstatistieken van onze search engine zijn niet gebonden aan één specifieke app. Al onze apps gebruiken dezelfde search engine, en daarom zijn onderstaande cijfers een bundeling van ál onze apps.\n\nDaarnaast maken externe websites óók gebruik van onze search engine - bijvoorbeeld sommige regioloketten. Ook dit gebruik is meegenomen in de onderstaande statistieken.",
        api: "De gebruiksstatistieken van onze API zijn niet gebonden aan één specifieke app. All onze apps gebruiken dezelfde API, en daarom zijn onderstaande cijfers een bundeling van ál het gebruik.\n\nDaarnaast maken externe websites óók gebruik van onze API - bijvoorbeeld sommige regioloketten. Ook dit gebruik is meegenomen in de onderstaande statistieken.",
        realtime: "Het aantal bezoekers dat in realtime de app bekijkt (ook wel: het aantal bezoekers in de afgelopen 5 minuten). Het aantal wordt elke 20 seconden ververst. Als je deze pagina open laat staan, stopt het verversen na 5 minuten.",
        max_realtime_refresh_count: 15,
        total_visitors: "Het totaal aantal unieke bezoekers in de gekozen periode.",
        total_visits: "Het totaal aantal sessies in de gekozen periode. Een bezoekers kan meerdere sessies hebben.",
        total_pageviews: "Het totaal aantal paginaweergaven in de gekozen periode. Een sessie kan meerdere paginaweergaven hebben.",

        visitors: "Het aantal unieke bezoekers in de gekozen periode.",
        pageviews: "Het aantal paginaweergaven in de gekozen periode. Elke sessie kan meerdere paginaweeragven hebben.",
        visits: "Het aantal sessiesin de gekozen periode. Elke bezoeker kan meerdere sessies initiëren (wanneer hij de app meerdere malen bezoekt).",


        devices: 'Het type device dat wordt gebruikt door bezoekers.',
        sources: 'De bronnen, webistes of apps waar bezoekers vandaan komen of vanaf worden doorverwezen.',
        pages: "De best bezochte pagina's naar bezoekersaantal.",
        regions: "De regio's of provincies waar bezoekers van de app zich bevinden. Gebaseerd op IP-adres.",
        

        api_users: "Het totale aantal gebruikers dat de API consumeert, naar aantal; verstrekte API keys.",
        api_requests: "Het totaal aantal verzoeken dat is verwerkt door onze server via verstrekte API keys. Dit is exclusief verzoeken die geprocied zijn naar services van derden, zoals de search verzoeken of geocoding verzoeken.",

        searches: "Het aantal zoekopdrachten dat in uitgevoerd voor de index in de geselecteerde periode.",
        total_searches: "Het totale aantal zoekopdrachten dat in uitgevoerd voor de index in de geselecteerde periode.",
        top_searches: 'De vaakst uitgevoerde zoekopdrachten voor een specifieke index.'
    }


    const empty = {
        savvycal: {
            date: '2024-06-01',
            text: 'We hebben geen gegevens over geplande adviesgesprekken vóór juni 2024.'
        },
        plausible: {
            'onderwijsloket.com': {
                date: '2020-01-02',
                text: 'We hebben geen gegevens over websitebezoekers vóór januari 2020.'
            },
            'voorbereiden.onderwijsloket.com': {
                date: '2023-12-15',
                text: 'We hebben geen gegevens over websitebezoekers vóór 15 december 2023.'
            },
            'stats.onderwijsloket.com': {
                date: '2024-09-20',
                text: 'We hebben geen gegevens over websitebezoekers vóór 20 september 2024.'
            },
            'indebuurt.onderwijs.in': {
                date: '2024-08-20',
                text: 'We hebben geen gegevens over websitebezoekers vóór 20 augustus 2024.'
            }
            
        },
        algolia: {
            date: '2024-08-20',
            text: 'We hebben geen gegevens over search indices en gebruiksgegevens daarvan van vóór 20 augustus 2024.'
        },

        spotify: {
            date: '2023-10-24',
            text: 'We hebben geen gegevens over podcasts van vóór 24 oktober 2023.'
        },
    }

    const media = {
        total_episodes: "Het aantal afleveringen dat is gepubliceerd voor de gekozen show.",
        total_listens: "Het totaal aantal keer dat een podcast show in geluisterd in de gekozen periode.",
        daily_listens: "Het aantal keer dat de afleveringen van deze show zijn geluisterd naar gekozen periode.",
        episode_rankings: "Het aantal keer dat een individuele aflevering is beluisterd. Deze data is niet te filteren op periode, en bevat dus altijd het totaal aantal plays sinds een aflevering is gepubliceerd."
    }


    return {
        advies,
        content,
        platform,
        empty,
        media
    }
}