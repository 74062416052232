<template>
  <DashboardCard
    :icon="icon"
    :title="title"
    :loading="loading"
    :height="440"
    :trend="trendData"
    :hint="visitors"
    :empty="isEmpty"
    :empty-hint="emptyHint"
    :error="!!error"
    @retry="emit('retry')"
  >
    <PlausibleDataTypeSelect class="-mt-3 mb-8" @select="(val) => selected = val" />
    <div class="w-full h-[376px]">
      <Line :data="chartData" :options="chartOptions" />
    </div>
  </DashboardCard>
  
</template>

<script setup lang="ts">
import type { App } from '~~/types'
const props = defineProps<{
  loading: boolean
  app: App["label"]
  data?: Record<string, {
    events: number,
    pageviews: number
    visits: number
    visitors: number
    visit_duration: number
    bounce_rate: number
  }>
  trends?: {
    events: number | 'infinite',
    pageviews: number | 'infinite'
    visits: number | 'infinite'
    visitors: number | 'infinite'
    visit_duration: number | 'infinite'
    bounce_rate: number | 'infinite'
  } | null
  error?: any
}>()

const emit = defineEmits<{
  (e: 'retry'): void
}>()

const { 
  visitors,
} = useStatsHints().platform

const { 
  plausible,
} = useStatsHints().empty

const {
    Line,
    baseLineOptions
} = useCharts()

const { dateRange } = useStatsQuery()

// const isEmpty = computed(() => !props.data.datasets[0]?.data.length || props.data.datasets[0]?.data.reduce((acc, cur) => acc + cur,0) === 0)
const isEmpty = false
const emptyHint = computed(() => {
  if (plausible[props.app] && dateRange.value.end < new Date(plausible[props.app].date)) return plausible[props.app].text
})

import type { PlausibeDataType } from '~~/types';
const selected: Ref<PlausibeDataType> = ref('pageviews')

const title = computed(() => {
  if (selected.value === 'pageviews') return 'Aantal paginaweergaven'
  if (selected.value === 'visits') return 'Aantal sessies'
  return 'Aantal bezoekers'
})

const trendData = computed(() => {
  return props.trends?.[selected.value] || 0
})

const chartData = computed(() => ({
  labels: !props.data ? [] : Object.keys(props.data),
  datasets: [
    {
      label: title.value,
      data: !props.data ? [] : Object.values(props.data).map((val) => val[selected.value]),
    },
  ],
}))

const chartOptions: Ref<any> = ref(baseLineOptions)

const icon = computed(() => {
  if (selected.value === 'pageviews') return 'i-heroicons-eye-20-solid'
  if (selected.value === 'visits') return 'i-heroicons-computed-desktop-20-solid'
  return 'i-heroicons-user-group-20-solid'
})

</script>