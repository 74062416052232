<template>
  <li class="flex flex-row items-start gap-3 justify-between w-full">
    <div class="flex flex-row items-start gap-3 select-none text-gray-700 dark:text-gray-200">
      <img v-if="!!src" :src="src" width="40" height="40" class="object-left object-contain h-5 w-5" />
      <UIcon v-else name="i-heroicons-link-20-solid" class="w-5 h-5 text-gray-500" />
      <NuxtLink v-if="!!link" :to="link" tagert="_blank" class="text-sm hover:underline truncate">{{ data.source }}</NuxtLink>
      <span v-else class="text-sm truncate">{{ data.source }}</span>
    </div>
    <span class="text-sm font-bold">{{ count }}</span>
  </li>
</template>

<script lang="ts" setup>
const props = defineProps<{
  data: {
    source: string
    visitors: number
  }
}>()

const sourceMap = {
  'Google': {
    favicon: 'https://google.com/favicon.ico',
    link: 'https://google.com/'
  },
  'Bing': {
    favicon: 'https://bing.com/favicon.ico',
    link: 'https://bing.com/'
  },
  'DuckDuckGo': {
    favicon: 'https://duckduckgo.com/favicon.ico',
    link: 'https://duckduckgo.com/'
  },
  'Ecosia': {
    favicon: 'https://ecosia.org/favicon.ico',
    link: 'https://ecosia.org/'
  },
  'LinkedIn': {
    favicon: 'https://linkedin.com/favicon.ico',
    link: 'https://linkedin.com/'
  },
  'Yahoo!': {
    favicon: 'https://yahoo.com/favicon.ico',
    link: 'https://yahoo.com/'
  },
  'Facebook': {
    favicon: 'https://facebook.com/favicon.ico',
    link: 'https://facebook.com/'
  },
  'Gmail': {
    favicon: 'https://mail.google.com/favicon.ico',
    link: 'https://mail.google.com/'
  },
  'Yandex': {
    favicon: 'https://yandex.com/favicon.ico',
    link: 'https://yandex.com/'
  },
  'Instagram': {
    favicon: 'https://instagram.com/favicon.ico',
    link: 'https://instagram.com/'
  },
  'Twitter': {
    favicon: 'https://twitter.com/favicon.ico',
    link: 'https://twitter.com/'
  },
  'Reddit': {
    favicon: 'https://reddit.com/favicon.ico',
    link: 'https://reddit.com/'
  },
  'universiteitenvannederland': {
    favicon: 'http://www.google.com/s2/favicons?domain=universiteitenvannederland.nl',
    link: 'https://universiteitenvannederland.nl'
  }
};

type Key = keyof typeof sourceMap


const link = computed(() => {
  if (!!sourceMap[props.data.source as Key]) return sourceMap[props.data.source as Key].link
  else {
    if (props.data.source === 'Direct / None') return ''
    try {
      const url = new URL('https://' + props.data.source)
      return url.origin
    } catch {
      return ''
    }
  }
})

const src = computed(() => {
  if (!!sourceMap[props.data.source as Key]) return sourceMap[props.data.source as Key].favicon
  else if (link.value) return `http://www.google.com/s2/favicons?domain=${new URL(link.value).host}`
  return ''
})

const count = computed(() => formatNumber(props.data.visitors))
</script>

<style>

</style>